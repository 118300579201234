import React, { useState } from 'react';
import Allergy from './Allergy'

function AllergyAccordion(props) {
    const [isActive, setIsActive ] = useState(true);

    return <div className="accordion">
        <h4 className={isActive ? "accordion-title" : "accordion-title close"} onClick={() => setIsActive(!isActive)}>Allergies</h4>
        
        {isActive && props.data.map(allergy => 
            <Allergy name={`${allergy.description} (${allergy.rxNormDescription})`}/>
        )}
        
        
    </div>
}

export default AllergyAccordion;