import React, { useState } from 'react';
import Document from './Document'

function DocumentAccordion(props) {
    //console.log("DocumentAccordion called and given:");
    //console.log("DocumentAccordion(",props.data);
    //console.log("DocumentAccordion(",props.personId);

    const [isActive, setIsActive ] = useState(true);
    
    return ( props.data &&
        <div className="accordion">
        <h4 className={isActive ? "accordion-title" : "accordion-title close"} onClick={() => setIsActive(!isActive)}>Documents</h4>
        {isActive &&
            <div className="content">
                {
                    props.data && 
                    props.data['items'].map( document => {
                        return (<Document 
                            key={document.id} 
                            link={`/api/document/${props.personId}/${document.id}`} 
                            description={document.description} 
                            header={props.header}
                            enc={props.detailed && document.encounterId}
                            date={props.detailed && document.createTimestamp}
                            personId={props.personId}/>);
                    })
                }
            </div>
        }
    </div>
    )
}

export default DocumentAccordion;