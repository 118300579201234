import React, { useState, Fragment, useEffect } from 'react';
import RowData from './RowData'
import { dateFromNGDateString } from '../Utility'
import Loading from './loading';

function Vitals(props){

    return <div className="vitals">
        <h4>Vitals</h4>
        <ListVitals data={props.data} header={props.header}/>
    </div>
}

function ListVitals(props) {
    return props.data["items"].map(vital => {
        return <VitalsTemplate key={vital.id}
            vitalId={vital.id}
            personId={vital.personId}
            encounterId={vital.encounterId}
            date={dateFromNGDateString(vital.vitalSignsDate)}
            time={vital.vitalSignsTime}
            header={props.header}
        />
    })
}

function VitalsTemplate(props) {
    const [isActive, setIsActive ] = useState(false);
    const [vitalsData, setVitalsData] = useState(null);

    useEffect(() => { 
        if (isActive && !vitalsData && props.personId && props.encounterId && props.vitalId) {
            fetch(`/api/vitals/${props.personId}/${props.encounterId}/${props.vitalId}`, {headers: props.header})
                .then((res) => res.json())
                .then((data) => setVitalsData(data))
                .then(console.log(vitalsData));
        }
    });

    return <>
        {props.personId ? <div className="accordion">
            <h5 className={isActive ? "accordion-title" : "accordion-title close"} onClick={() => setIsActive(!isActive)}>
                {props.date.toLocaleDateString('en-us', { month:"short", day:"numeric", year:"numeric"})} {props.time}
            </h5>
            {isActive &&
            <div className="content">
                {!vitalsData && <Loading/>}
                {vitalsData &&
                <div className="info-table">
                    <RowData label="Height" value={<Fragment>{vitalsData.heightInchesGraph} in</Fragment>}/>
                    <RowData label="Weight" value={<Fragment>{vitalsData.weightPounds} lbs</Fragment>}/>
                    <RowData label="BMI" value={<Fragment>{vitalsData.bmi} kg/m<sup>2</sup></Fragment>}/>
                    <RowData label="Pulse Rate" value={<Fragment>{vitalsData.pulseRate} min</Fragment>}/>
                    <RowData label="Blood Presure" value={<Fragment>{vitalsData.bloodPressure} mm[Hg]</Fragment>}/>
                    <RowData label="Temperature" value={ <Fragment>{vitalsData.temperatureDegreesFahrenheit}&#176;F</Fragment>}/>
                    <RowData label="Respitory Rate" value={<Fragment>{vitalsData.respirationRate} / min</Fragment>}/>
                </div>
                }
            </div>
            }
        </div> : <p>No Medical Record</p>}
    </>
    
}
export default Vitals;