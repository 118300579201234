import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function LoginButton() {
  const { isAuthenticated, loginWithRedirect, } = useAuth0();

  return !isAuthenticated && (
    <button className="primary-btn btn-small logout-btn"  onClick={loginWithRedirect}>Log in</button>
  );
}

export default LoginButton;