import React, {useEffect, useState} from 'react';
import { StaticImages, dateFromNGDateString } from "../Utility";
import Loading from './loading';
import { saveAs } from 'file-saver'

function Document(props) {
    const [isDownloading, setIsDownloading] = useState(false);
    // const [encounterData, setEncounterData] = useState(null);

    const downloadFile = async() => {
        if (isDownloading) {
            return;
        }
        setIsDownloading( true );

        fetch(`${props.link}`, {headers: props.header})
                .then(res => res.blob())
                .then(blob => saveAs(blob, props.description+'.pdf'))
                .then(x => setIsDownloading(false))
    }

    // useEffect(() => {
    //     if (props.personId != null && props.personId != "" && props.enc) {
    //         fetch(`/api/encounters/${props.personId}/${props.enc}`, {headers: props.header})
    //             .then((res) => res.json())
    //             .then((data) => setEncounterData(data))
    //     }
    // }, [props.personId, props.enc]);
    
    return <div className='doc-download'>
        {props ? <button className='btn a' onClick={(downloadFile)} disabled = {isDownloading}>
                <img src={StaticImages["document.svg"]} />
                {isDownloading ? 'Downloading...' : props.description + (props.date ? " - " + dateFromNGDateString(props.date)?.toLocaleDateString('en-us', { month: "short", day: "numeric", year: "numeric" }) : "")}
            </button>
            : <Loading/>
        }
    </div>

}
export default Document;