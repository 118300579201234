// src/version.js
import React from 'react';
import version from '../../package.json';

function Version() {
    if (process.env.REACT_APP_ENV != "NGProd")
    {
        return (
            <p>
                © {version.copyright} {version.title} &nbsp;
                <small>
                    <a href={"https://dev.azure.com/NCUC/Application%20Development%20-%20Core/_git/PatientPortal"}>
                        v{version.buildMajor}.{version.buildMinor}
                    </a> 
                    <i>{process.env.REACT_APP_ENV}</i>
                </small>
            </p>
        )
    }
    else 
        return (
            <p>
                © {version.copyright} {version.title} &nbsp;
                <small>v{version.buildMajor}.{version.buildMinor}</small>
            </p>
        );
}
export default Version;