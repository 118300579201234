// src/components/nav-bar.js
import React from 'react';
import { NavAtResource, StaticImages } from '../Utility';
import { Link } from "react-router-dom";

const closeMenu = () => {
    //props.setMenuActive(false);
    document.body.classList.remove('active-menu');
};

function SideBar() {
    return (
    <ul className="sidebar-menu">
        {CreateListItemElementForResource("Appointments", "prior-visits-menu.svg", "Prior Visits","Full visit archive and summaries")}
        {CreateListItemElementForResource("Labs","lab-results-menu.svg", "Lab Results","View all current and past labsy",)}
        {CreateListItemElementForResource("Xrays","x-ray-results-menu.svg", "X-Ray Results","Access x-ray results and interpretations")}
        {CreateListItemElementForResource("Medications","medications-menu.svg", "Medications","Dosage, instructions, and more")}
        {CreateListItemElementForResource("HealthRecordSummary","health-record-summary-menu.svg", "Health Record Summary","Comprehensive health history")}
    </ul>
  );
};

function CreateListItemElementForResource(resource, image, info, infoBrief)
{
    return(
        <li key={'link-'+resource} className={NavAtResource(resource) ? "active" : ""}>
            <Link to={"/"+resource} onClick={closeMenu} >
                <img src={StaticImages[image]} alt={info} />
                <div className="info">
                    <h5>{info}</h5>
                    <p>{infoBrief}</p>
                </div>
            </Link>
        </li>
    );
}

export default SideBar;