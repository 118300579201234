import React, { useState } from 'react';
import { dateFromNGDateString } from '../Utility';
import Condition from './Condition';

function ConditionAccordion(props){
    const [isActive, setIsActive ] = useState(true);
    return <div className="accordion">
        <h4 className={isActive ? "accordion-title" : "accordion-title close"} onClick={() => setIsActive(!isActive)}>Conditions</h4>
        {isActive && 
        <div className="content medication">
            {
                props.data.map(diag => 
                    <Condition
                        name={diag.description}
                        effective_date={diag.onsetDate && dateFromNGDateString(diag.onsetDate)}
                        related_visit_date={dateFromNGDateString(diag.encounterTimestamp)}
                        condition_status={diag.statusDescription}
                        comments={diag.notes}
                    />
                )
            }
        </div>
        }
    </div>
}

export default ConditionAccordion;