import BackButton from './components/back-button';

//This content is sourced from https://nextcare.com/compliance/
export function Compliance() {
    return (
        <>
            <div className="page-top">
                <BackButton/>
                <h4>Compliance</h4>
            </div>
            <div className='lab-results-wrapper'>

                <div className="lab-text-box">
                    <h2>NextCare desires to ensure that its operations are conducted in accordance with all federal, state and local laws and regulations.</h2>
                    <p>To underscore and enhance its commitment and to better assist all those working at and with NextCare in understanding their obligation to comply with all laws and regulations, NextCare has implemented a Compliance Plan that formalizes previous and current NextCare practices. NextCare’s Compliance Plan is intended to establish a framework for legal compliance by NextCare and its Team Members, including NextCare shareholders and the partners of its related entities.</p>
                    <p>As a team member, customer, or partner of NextCare, we strongly encourage you to report by name, or anonymously, any potential compliance violation through any of the contact methods mentioned below. All reports will be reviewed by the compliance department. Individuals reporting concerns are assured there will be no risk of retaliation.</p>
                    <p>To report possible <span style={{color: '#ff0000'}}>compliance</span> violations, contact:</p>
                    <p>If an employee – 1-800-729-1381 or email <a href="mailto:compliance@nextcare.com">compliance@nextcare.com</a></p>
                    <p>Report anonymously to the Compliance Hotline</p>
                    <p>If a patient, call 1-888-381-4858 or email <a href="mailto:wecare@nextcare.com">wecare@nextcare.com</a></p>
                </div>

            </div>
        </>
    )
}