function AppointmentDetailHeader(props) {
    console.log(props);
    return (
        <div className="appointment-detail-info">
            <h2 className="divider-exist">{props.appt_event.replace(" Visit", "")} Visit</h2>
            <h4>
                <span>{props.appt_date.toLocaleDateString('en-us', { month:"short", day:"numeric"/*, year:"numeric"*/})}</span> at 
                <span> {props.appt_date.toLocaleTimeString('en-us', { hour12:"true", hour:"numeric", minute:"2-digit"})}</span>
            </h4>
            <h5>{props.provider}</h5>
            <h5>{props.location}</h5>
        </div>
    )
}

export default AppointmentDetailHeader;    