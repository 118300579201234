import React, { useState, useEffect } from 'react';
import BackButton from './components/back-button';
import PatientInformation from './components/PatientInformation';
import ContactInformation from './components/ContactInformation';
import CareTeamMemberAccordion from './components/CareTeamMemberAccordion';
import AllergyAccordion from './components/AllergyAccordion';
import MedicationAccordion from './components/ListMedications';
import ConditionAccordion from './components/ConditionAccordion';
import VitalSign from './components/VitalSign';
import Loading from './components/loading';
import { useNavigate } from 'react-router-dom';
import DocumentAccordion from './components/DocumentAccordion';

function HealthRecordSummary(props) {
    const [demographicData, setDemographicData] = useState(null);
    const [careteamData, setCareteamData] = useState(null);
    const [vitalsData, setVitalsData] = useState(null);
    const [allergiesData, setAllergiesData] = useState(null);
    const [diagnosesData, setDiagnosesData] = useState(null);
    const [docData, setDocData] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (props.personId != null && props.personId != "") {
          fetch(`/api/demographics/${props.personId}`, {headers: props.header})
            .then((res) => res.json())
            .then((data) => setDemographicData(data))
            .then(console.log(demographicData))
            .catch(error => {
              navigate("/500");
            })
        }
    }, [props.personId]);

    useEffect(() => {
        if (props.personId != null && props.personId != "") {
            fetch(`/api/documents/${props.personId}`, {headers: props.header})
            .then((res) => res.json())
            .then((data) => setDocData(data))
        }
    }, [props.personId]);

    useEffect(() => {
        if (props.personId != null && props.personId != "") {
            fetch(`/api/vitals/${props.personId}`, {headers: props.header})
                .then((res) => res.json())
                .then((data) => setVitalsData(data))
        }
    }, [props.personId]);

    useEffect(() => {
        if (props.personId != null && props.personId != "") {
            fetch(`/api/careteam/${props.personId}`, {headers: props.header})
                .then((res) => res.json())
                .then((data) => setCareteamData(data))
                .then(console.log(careteamData))
        }
    }, [props.personId]);

    useEffect(() => {
        if (props.personId != null && props.personId != "") {
            fetch(`/api/allergies/${props.personId}`, {headers: props.header})
                .then((res) => res.json())
                .then((data) => setAllergiesData(data))
                .then(console.log(allergiesData))
        }
    }, [props.personId]);

    useEffect(() => {
        if (props.personId != null && props.personId != "") {
            fetch(`/api/diagnoses/${props.personId}`, {headers: props.header})
                .then((res) => res.json())
                .then((data) => setDiagnosesData(data))
        }
    }, [props.personId]);

    return <>
        <PageTop />
        <div className='appointments-wrapper'>
            {demographicData ? <PatientInformation
                data={demographicData}
                personId={props.personId}
                header={props.header}
            /> : <Loading />}
            {docData ? <DocumentAccordion data={docData} personId={props.personId} header={props.header} detailed={true}/> : <Loading />}
            {demographicData ? <ContactInformation data={demographicData} personId={props.personId} /> : <Loading />}
            {careteamData ? <CareTeamMemberAccordion data={careteamData} personId={props.personId} /> : <Loading />}
            {allergiesData ? <AllergyAccordion data={allergiesData["items"]} /> : <Loading />}
            <MedicationAccordion personId={props.personId} header={props.header}/>
            {diagnosesData ? <ConditionAccordion data={diagnosesData["items"]} /> : <Loading />}
            {vitalsData ? <VitalSign data={vitalsData} header={props.header}/> : <Loading />}
        </div>
    </>
}

function PageTop() {
    return <div className="page-top">
        <BackButton />
        <h4>Health Record Summary</h4>
    </div>
}

export default HealthRecordSummary;