import React, { useEffect, useState } from 'react';
import Loading from './loading';
import RowData from './RowData'

function PatientInformation(props){
    const [isActive, setIsActive ] = useState(true);
    const [ethnicityData, setEthicityData] = useState(null);
    const [raceData, setRaceData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [practiceData, setPracticeData] = useState(null);

    useEffect(() => {
        
        if (props.personId != null && props.personId != "") {
            fetch(`/api/ethnicities/${props.personId}`, {headers: props.header})
                .then(res => res.json())
                .then(data => setEthicityData(data));

            fetch(`/api/races/${props.personId}`, {headers: props.header})
                .then(res => res.json())
                .then(data => setRaceData(data));

            fetch(`/api/chart/${props.personId}`, {headers: props.header})
                .then(res => res.json())
                .then(data => {console.log(data); setChartData(data)});
        }
    }, [props.personId]);

    useEffect(() => {
        if (chartData != null) {
            Promise.all(chartData.map(chart => 
                fetch(`/api/practice/${chart.practiceId}`, {headers: props.header})
                .then(res => res.json())))
            .then(responses => setPracticeData(responses));
        }
    }, [chartData]);
    
    return <div className="accordion">
        <h4 className={isActive ? "accordion-title" : "accordion-title close"} onClick={() => setIsActive(!isActive)}>Patient Information</h4>
        {isActive && 
        <div className="content">
            {chartData && raceData && ethnicityData && practiceData ? 
                <div className="info-table">
                    <RowData label="First Name" value={props.data.firstName} />
                    <RowData label="Middle Name" value={props.data.middleName}/>
                    <RowData label="Last Name" value={props.data.lastName}/>
                    <RowData label="Birth Date" value={props.data.dateOfBirth?.split("T")[0]}/>
                    <RowData label="Race" value={raceData?.map(race => race.description).join(", ")}/>
                    <RowData label="Ethnicity" value={[...new Set(ethnicityData?.map(ethnicity => ethnicity.description))].join(", ")}/>
                    <RowData label="Sex" value={props.data.sex}/>
                    <RowData label="Marital Status" value={props.data.maritalStatus}/>
                    <RowData label="Medical Record Number(s)" value={chartData?.map(chart => 
                        `${practiceData.filter(practice => practice.practiceId === chart.practiceId)[0]?.practiceName}: ${chart.medicalRecordNumber}`)
                        .reduce((acc, mrn) => acc===null ? <>{mrn}</> : <>{acc}<br />{mrn}</>, <></>)}/>
                </div> : <Loading/>
            }
            
        </div>}
    </div>
}

export default PatientInformation;