import React, { useState } from 'react';

export function MissingInfo(props) {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [missing, setMissing] = useState('');
    const [details, setDetails] = useState('');
    const [checkRequired, setCheckRequired] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    }

    const handleMissingChange = (event) => {
        setMissing(event.target.value);
    }

    const handleDetailsChange = (event) => {
        setDetails(event.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const requiredList = [email, phone, missing];
        if (requiredList.some(x => {
            return x == null || x.length == 0
        })) {
            setCheckRequired(true);
            return;
        }

        var data = {
            "email": email,
            "phone": phone,
            "missingData": missing,
            "details": details
        };
        
        props.setMissingInfo(data);

        fetch('/api/missing_info', {
            method: "POST",
            mode: "cors", 
            credentials: "same-origin", 
            headers: {
              "Content-Type": "application/json",
              ...props.header
            },
            body: JSON.stringify(data),
        }).then((res) => console.log(res.json()));
    };

    return <>
        {props.missingInfo != undefined && <h2>Thank you! We will process your request as soon as possible</h2>}
        {props.missingInfo != undefined || 
            <>
                <h2>Are you missing some of your medical records?</h2>
                <h3>Help us find them.</h3>
                <label>Email Address (as it appears in your medical record) *</label>
                <input required type="email" onChange={handleEmailChange}/>
                {checkRequired && email.length == 0 &&
                    <p className='notice-text'>Please fill out this field</p>}
                <label>Phone number (as it appears in your medical record) *</label>
                <input required type="tel" onChange={handlePhoneChange}/>
                {checkRequired && phone.length == 0 &&
                    <p className='notice-text'>Please fill out this field</p>}
                <label>What information is missing in the app? *</label><br />
                <textarea required onChange={handleMissingChange}/><br />
                {checkRequired && missing.length == 0 &&
                    <p className='notice-text'>Please fill out this field</p>}
                <label>Any additional details?</label><br />
                <textarea onChange={handleDetailsChange}/><br />
                <button onClick={handleSubmit}>Submit</button>
            </>
        }
        
    </>
    
}