import { useState, useEffect } from 'react';
import {dateFromNGDateString} from './Utility.js';

import AccordionJSONSection from './components/AccordionJSONSection';
import BackButton from './components/back-button';
import Loading from './components/loading.js';
import { useNavigate } from 'react-router-dom';

function XRay(props) {
    const [resultsData, setResultsData] = useState(null);
    const [diagnosticsData, setDiagnosticsData] = useState(null);
    const [page, setPage] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasNext, setHasNext] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (props.personId != null && props.personId != "") {
            fetch(`/api/xrays/results/${props.personId}`, {headers: props.header})
            .then((res) => res.json())
            .then((data) => setResultsData(data))
            .then(console.log(resultsData));

            fetch(`/api/xrays/diagnostics/${props.personId}`, {headers: props.header})
            .then((res) => res.json())
            .then((data) => setDiagnosticsData(data))
            .then(console.log(diagnosticsData))
            .catch(error => {
                navigate("/500");
            })
        }
    }, [props.personId]);

    useEffect(() => {
        if (diagnosticsData != null) {
          setLoadingMore(true);
          fetch(`/api/xrays/diagnostics/${props.personId}?page=${page}`, {headers: props.header})
            .then(res => res.json())
            .then(data => {
              setDiagnosticsData({items: setDiagnosticsData["items"].concat(data["items"])});
              setHasNext(data["hasNext"]);
            })
            .then(x => {
              setLoadingMore(false);
            });
        }
        
      }, [page]);

    return <>
          <div className="page-top">
            <BackButton/>
            <h4>X-Ray Results</h4>
          </div>
          {props.personId ? <div className="lab-results-wrapper">
            {diagnosticsData && resultsData? <ListXRayResults diagnosticsData={diagnosticsData} resultsData={resultsData}/> : <Loading/>}
            {hasNext && <button onClick={() => setPage(page + 1)} disabled={loadingMore}>
                {loadingMore ? "Loading" : "Load More"}
            </button>}
          </div> : <p>No Medical Record</p>}
          
          {/*json data*/}
          <AccordionJSONSection id="AccordionDiagnosticJSON" name="X-Ray Diagnostic Data" data={diagnosticsData}/>
          <AccordionJSONSection id="AccordionResultsJSON" name="X-Ray Results Data" data={resultsData}/>
    </>
}

function ListXRayResults(props) {
    return (props.diagnosticsData["items"].filter((diag) => 
        diag.testStatus === "Final"
    ).map(diag => {
        console.log(diag.id);
        return <XRayTemplate key={diag.id}
            id={diag.id}
            name={diag.testDescription}
            date={dateFromNGDateString(diag.encounterTimestamp)}
            results={props.resultsData["items"].filter(res => 
                res.orderId==diag.id
            ).map(res => {
                console.log(res);
                return {
                    id:res.observationSequenceNumber,
                    val:res.observationValue
                }
                
            })}
        />
    }));
}

function XRayTemplate(props) {
    console.log(props);
    return (
        <div className="lab-text-box">
        <p className='test-name'>Test Name</p>
        <p className='lab-text-box-title'>
            <h5>{props.name}</h5>
        </p>
        <p className="lab-text-box-diagnosis">Result</p>
        {
            props.results.map(res => {
                return <p key={res.id} className="resDesc">{res.val}</p>
            })
        }
        <div className="lab-text-box-info-wrapper info-wrapper">
            <div className="info">
                <p className="label">Date</p>
                <p className="value">{props.date.toLocaleDateString('en-us', { month:"short", day:"numeric", year:"numeric"})}</p>
            </div>
            <div className="info">
                <p className="label">Time</p>
                <p className="value">{props.date.toLocaleTimeString('en-us', { hour12:"true", hour:"numeric", minute:"2-digit"})}</p>
            </div>
        </div>
    </div>
    )
}

export default XRay;