import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import AccordionJSONSection from './components/AccordionJSONSection';
import BackButton from './components/back-button';
import Loading from './components/loading';
import { isGuid } from './Utility';

function Appointments(props) {
  const [apptData, setApptData] = useState(null);
  const [providerData, setProviderData] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [page, setPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [hasNext, setHasNext] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.personId != null && props.personId != "") {
      fetch(`/api/appointments/${props.personId}`, {headers: props.header})
        .then((res) => res.json())
        .then((data) => {
          setApptData(data);
          setHasNext(data["hasNext"]);
        })
        .then(console.log(apptData))
        .catch(error => {
          navigate("/500");
        })
    }

  }, [props.personId]);

  useEffect(() => {
    if (apptData != null) {
      setLoadingMore(true);
      fetch(`/api/appointments/${props.personId}?page=${page}`, {headers: props.header})
        .then(res => res.json())
        .then(data => {
          setApptData({items: apptData["items"].concat(data["items"])});
          setHasNext(data["hasNext"]);
        })
        .then(x => {
          setLoadingMore(false);
        });
    }
    
  }, [page]);

  //When appdata has been fetched, fetch location and provider data
  useEffect(() => {
    if (!apptData) {
      return;
    }
    setLoadingLocations(true);
    setLoadingProviders(true);

    const providerURLParams = new URLSearchParams();
    [...new Set(apptData["items"].map(appt => appt.renderingProviderId))].filter(provId => provId).map(provId => {
      providerURLParams.append("id", provId);
    });
    fetch(`/api/providers?${providerURLParams.toString()}`, {headers: props.header})
      .then((res) => res.json())
      .then((data) => setProviderData(data))
      .then(x => setLoadingProviders(false));

    const locationURLParams = new URLSearchParams();
    [...new Set(apptData["items"].map(appt => appt.locationId))].filter(locId => locId).map(locId => {
      locationURLParams.append("id", locId);
    });
    fetch(`/api/locations?${locationURLParams.toString()}`, {headers: props.header})
      .then((res) => res.json())
      .then((data) => setLocationData(data))
      .then(x => setLoadingLocations(false));
  }, [apptData]);

  return (
    <>
      <div className="page-top">
        <BackButton />
        <h4>Prior Visits</h4>
      </div>
      {props.personId ? <div className="appointments-wrapper">
        {apptData ? <ListAppointments
          apptData={apptData}
          locationData={locationData}
          loadingLocations={loadingLocations}
          providerData={providerData}
          loadingProviders={loadingProviders}
        /> : <Loading />}
        {hasNext && <button onClick={() => setPage(page + 1)} disabled={loadingMore}>
          {loadingMore ? "Loading" : "Load More"}
        </button>}
      </div> : <p>No Medical Record</p>}

      {/*json data*/}
      <AccordionJSONSection name="Appointments" data={apptData} />
    </>
  );
}

//Foreach appointment in the JSON, build the Appointment Template section
function ListAppointments(props) {
  console.log(props.apptData);
  return (props.apptData["items"].map(appt => {
    var date = appt.appointmentDate.split("T")[0].split("-");
    var year = date[0];
    var month = date[1] - 1;//getMonth() returns [0..11]
    var day = date[2];
    var hour = appt.beginTime.substring(0, 2);
    var minute = appt.beginTime.substring(2);
    var apptDate = new Date(year, month, day, hour, minute);
    var provider = props.providerData?.["items"]
      ?.filter(provider => provider.id == appt?.renderingProviderId)[0]?.description ?? appt.renderingProviderId;
    var location = props.locationData?.["items"]
      ?.filter(location => location.id == appt.locationId)[0]?.name ?? appt.locationId;

    return <AppointmentTemplate
      key={appt.id}
      id={appt.id}
      encounterId={appt.encounterId}
      appt_date={apptDate}
      appt_event={appt.eventName}
      isCancelled={appt.isCancelled}
      provider={provider}
      loadingProviders={props.loadingProviders}
      location={location}
      loadingLocations={props.loadingLocations}
    />
  }))
}

ListAppointments.defaultProps = {
  apptType: "all"
}

function AppointmentTemplate(props) {
  let hasEnc = props.encounterId !== null;

  //console.log("Appoint template enc_id=",props.encounterId);
  return (
    <div className={hasEnc ? "" : "appointment-box-require-encounter"}>
      <Link to={`/AppointmentDetails/${props.encounterId}`} className={"appointment-box" + (hasEnc ? "" : " appointment-box-disabled")}
        state={{
          encounterId: props.encounterId,
          appt_event: props.appt_event,
          provider: props.provider,
          location: props.location,
          appt_date: props.appt_date
        }}
      >
        <h4>{props.appt_event}</h4>
        {props.provider != null ? <p>{GuidProperty(props.provider, props.loadingProviders, "Provider")}</p> : <></>}
        <p>{GuidProperty(props.location, props.loadingLocations, "Location")}</p>
        <p className="time">
          <span>{props.appt_date.toLocaleDateString('en-us', { month: "short", day: "numeric", year: "numeric" })}</span>
          <span>{props.appt_date.toLocaleTimeString('en-us', { hour12: "true", hour: "numeric", minute: "2-digit" })}</span>
        </p>
      </Link>
    </div>
  );
}

function GuidProperty(data, isLoading, propertyName="Provider") {
  if (isLoading) {
    return "Loading...";
  } else if (isGuid(data)) {
    return `Unknown ${propertyName}`
  } else {
    return data;
  }
}

export default Appointments;