import { useState, useEffect } from 'react';
import {dateFromNGDateString} from './Utility.js';

import AccordionJSONSection from './components/AccordionJSONSection';
import BackButton from './components/back-button';
import Loading from './components/loading';
import { useNavigate } from 'react-router-dom';

function Labs(props) {
  const [labData, setLabData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [page, setPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasNext, setHasNext] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.personId != null && props.personId != "") {
      fetch(`/api/labs/${props.personId}`, {headers: props.header})
        .then((res) => res.json())
        .then((data) => {
          setLabData(data);
          setHasNext(data["hasNext"]);
        })
        .then(console.log(labData))
        .catch(error => {
          navigate("/500");
        })
    }
  }, [props.personId]);

  useEffect(() => {
    if (labData != null) {
      setLoadingMore(true);
      fetch(`/api/labs/${props.personId}?page=${page}`, {headers: props.header})
        .then(res => res.json())
        .then(data => {
          setLabData({items: labData["items"].concat(data["items"])});
          setHasNext(data["hasNext"]);
          setLoadingMore(false);
        });
    }
    
  }, [page]);

  useEffect(() => {
    if (props.personId != null && props.personId != "") {
      fetch(`/api/labs/orders/${props.personId}`, {headers: props.header})
        .then((res) => res.json())
        .then((data) => setOrderData(data))
    }
  }, [props.personId])
  
  return <>
      <div className="page-top">
        <BackButton/>
        <h4>Lab Results</h4>
      </div>
      {props.personId ? <div className="lab-results-wrapper">
        {labData && orderData ? <ListLabResults data={labData} orderData={orderData} /> : <Loading/>}
        {hasNext && <button onClick={() => setPage(page + 1)} disabled={loadingMore}>
          {loadingMore ? "Loading" : "Load More"}
        </button>}
      </div> : <p>No Medical Record</p>}
      
      {/*json data*/}
      <AccordionJSONSection name="Lab Results" data={labData}/>
  </>
}

function ListLabResults(props) {
  const minutes = process.env.REACT_APP_LAB_RESULT_DELAY;
  return( props.data["items"].filter(lab => 
      (Date.now() - new Date(lab.observationDateTimeUtc).getTime()) > minutes * 10 * 100 * 60
    ).filter(lab =>
      lab.observationResultStatus === 'F' || 
      props.orderData["items"].filter(order =>
        order.id === lab.orderId
      )[0]?.testStatus === "Final"
  ).map(lab => {
          return <LabTemplate key={lab.panelId + lab.observationSequenceNumber}
              panelId={lab.panelId}
              osn={lab.observationSequenceNumber}
              obsId={lab.observationId}
              obsVal={lab.observationValue}
              abnormalityCode={lab.abnormalityCode}
              units={lab.units}
              date={lab.observationDateTime}
              comments={lab.resultComment}
          />
      }
  ));
}

function LabTemplate(props) {
  var title = props.obsId.split("^")[1];
  var date = dateFromNGDateString(props.date);
  var units = props.units ?? ""
  return (
    <div className='lab-text-box'>
      <p className='test-name'>Test Name</p>
      <p className='lab-text-box-title'>
        <h5>{title}</h5>
      </p>
      <p className='lab-text-box-diagnosis'>Result</p>
      <p className='desc'>{props.obsVal} {units}</p>
      <div className='lab-text-box-info-wrapper info-wrapper'>
        <div className='info'>
          <p className='label'>Date</p>
          <p className='value'>{date?.toLocaleDateString('en-us', { month:"short", day:"numeric", year:"numeric"})}</p>
        </div>
        <div className='info'>
          <p className='label'>Time</p>
          <p className='value'>{date?.toLocaleTimeString('en-us', { hour12:"true", hour:"numeric", minute:"2-digit"})}</p>
        </div>
      </div>
    </div>
  );
}

export default Labs;