import React from 'react';
import { Link } from "react-router-dom";

function BackButton(props) {
    return <Link to={props.destination} className="go-back" />
}

BackButton.defaultProps = {
    destination: "/"
}

export default BackButton;