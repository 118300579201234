// src/components/loading.js
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function Loading() {
  const { isLoading, error, } = useAuth0();
  if (isLoading) {
    return <LoadingDiv />
  } else if (error) {
    return <div>Oops... {error.message}</div>;
  } else {
    return <LoadingDiv />
  }
}

const LoadingDiv = () => {
  return (
    <div className="row gx-0 justify-content-md-center">
      <div id="display" className="col-12">
        <div className="card item" aria-hidden="true" id="result-card-template">
          <div className="card-body">
            <h5 className="card-title placeholder-glow">
              <span className="placeholder placeholder-lg col-6"></span>
            </h5>
            <p className="card-text placeholder-glow">
              <span className="placeholder placeholder-sm col-12 bg-secondary"></span>
              <span className="placeholder placeholder-sm col-12 bg-secondary"></span>
              <span className="placeholder placeholder-sm col-12 bg-secondary"></span>
              <span className="placeholder placeholder-sm col-12 bg-secondary"></span>
              <span className="placeholder placeholder-sm col-12 bg-secondary"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loading;