import { useState, useEffect } from 'react';
import AccordionJSONSection from './components/AccordionJSONSection';
import BackButton from './components/back-button';
import Loading from './components/loading';
import { ListMedications } from './components/ListMedications';

export function Medications(props) {

  return <>
      <div className="page-top">
        <BackButton />
        <h4>Medications</h4>
      </div>
      <div className="content medication">
        <div className="lab-results-wrapper lab-text-box ">
          <ListMedications personId={props.personId} header={props.header}/>
        </div>
      </div>
    </>
}