import BackButton from './components/back-button';
import React from 'react';
import AppointmentDetailHeader from './components/AppointmentDetailHeader';
import PatientInformation from './components/PatientInformation';
import ContactInformation from './components/ContactInformation';
import CareTeamMemberAccordion from './components/CareTeamMemberAccordion';
import AllergyAccordion from './components/AllergyAccordion';
import MedicationAccordion from './components/ListMedications';
import VitalSign from './components/VitalSign';
import ConditionAccordion from './components/ConditionAccordion';
import { useState, useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";
import DocumentAccordion from './components/DocumentAccordion';
import Loading from './components/loading';

function AppointmentDetails(props) {
  let { id } = useParams(); //encounterId but why isn't this part of props...
  const apptState = useLocation();
  const  {encounterId,appt_event,provider,location,appt_date } = apptState.state;

  const [docData, setDocData] = useState(null);
  const [demographicData, setDemographicData] = useState(null);
  const [vitalsData, setVitalsData] = useState(null);
  const [careteamData, setCareteamData] = useState(null);
  const [allergiesData, setAllergiesData] = useState(null);
  const [diagnosesData, setDiagnosesData] = useState(null);

  useEffect(() => {
    if (props.personId != null && props.personId != "") {
      fetch(`/api/documents/${props.personId}/${id}`, {headers: props.header})
        .then((res) => res.json())
        .then((data) => setDocData(data))
    }
  }, [props.personId]);

  useEffect(() => {
    if (props.personId != null && props.personId != "") {
      fetch(`/api/vitals/${props.personId}/${id}`, {headers: props.header})
      .then((res) => res.json())
      .then((data) => setVitalsData(data))
    }
  }, [props.personId]);

  useEffect(() => {
    if (props.personId != null && props.personId != "") {
      fetch(`/api/demographics/${props.personId}`, {headers: props.header})
        .then((res) => res.json())
        .then((data) => setDemographicData(data))
    }
  }, [props.personId]);

  useEffect(() => {
    if (props.personId != null && props.personId != "") {
      fetch(`/api/careteam/${props.personId}/${id}`, {headers: props.header})
        .then((res) => res.json())
        .then((data) => setCareteamData(data))
    }
  }, [props.personId]);

  useEffect(() => {
    if (props.personId != null && props.personId != "") {
      fetch(`/api/allergies/${props.personId}/${id}`, {headers: props.header})
        .then((res) => res.json())
        .then((data) => setAllergiesData(data))
    }
  }, [props.personId]);

  useEffect(() => {
    if (props.personId != null && props.personId != "") {
      fetch(`/api/diagnoses/${props.personId}/${id}`, {headers: props.header})
        .then((res) => res.json())
        .then((data) => setDiagnosesData(data))
    }
  }, [props.personId]);

  return (
    <>
      <div className="page-top">
        <BackButton destination="/Appointments" />
        <h4>Prior Visits</h4>
      </div>

      {props.personId ? <div className="appointments-wrapper">
        <div className="appointments-detail">
          <AppointmentDetailHeader appt_event={appt_event} provider={provider} location={location} appt_date={appt_date} />
          {docData ? <DocumentAccordion data={docData} personId={props.personId} header={props.header}/> : <Loading />}
          {demographicData ? <PatientInformation data={demographicData} personId={props.personId} header={props.header}/> : <Loading />}
          {demographicData ? <ContactInformation data={demographicData} personId={props.personId} /> : <Loading />}
          {careteamData ? <CareTeamMemberAccordion data={careteamData} personId={props.personId} /> : <Loading />}
          {allergiesData ? <AllergyAccordion data={allergiesData} /> : <Loading />}
          <MedicationAccordion personId={props.personId} header={props.header}/>
          {diagnosesData ? <ConditionAccordion data={diagnosesData} /> : <Loading />}
          {vitalsData ? <VitalSign data={vitalsData} header={props.header}/> : <Loading />}
        </div>
      </div> : <p>No Medical Record</p>}
    </> 
  );
}

export default AppointmentDetails;