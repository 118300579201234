import React, { useState } from 'react';
import CareTeamMember from './CareTeamMember';

function CareTeamMembers(props){
    const [isActive, setIsActive ] = useState(true);
    return <div className="accordion">
            <h4 className={isActive ? "accordion-title" : "accordion-title close"} onClick={() => setIsActive(!isActive)}>Care Team Members</h4>
            {isActive && 
                <div className="content">
                    <div className="info-table singe-child">
                        {props.data["items"].map(careteam => {
                            return <CareTeamMember member={careteam}/>
                        })}
                    </div>
                </div>
            }
        </div>
}

export default CareTeamMembers;