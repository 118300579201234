//import './App.css';

function Results() {
    return (
      <div className="Results">
        <h3>Patient Results Listing</h3>  
        <div className="row gx-0 justify-content-md-center">
           <div id="display" className="col-6">
              
              <div className="card item" aria-hidden="true" id="result-card-template">
                <div className="card-body">
                  <h5 className="card-title placeholder-glow">
                    <span className="placeholder col-6"></span>
                  </h5>
                  <p className="card-text placeholder-glow">
                    <span className="placeholder col-3"></span>
                    <span className="placeholder col-4"></span>
                    <span className="placeholder col-4"></span>
                    <span className="placeholder col-6"></span>
                    <span className="placeholder col-8"></span>
                  </p>
                </div>
              </div>
        </div>
      </div>
    </div>
    );
  }
  
  export default Results;