// src/dashboard.js
import React from 'react';
import { Link } from "react-router-dom";
import { StaticImages } from "./Utility";

function Dashboard() {
    return (
    <main className="main dashboard">
        <div className="container">
            <div className="welcome-app-page page-top">
                <h1 className="divider-exist">Welcome to My Records</h1>
                <h3>How can we help you today?</h3>
            </div>
            <div className="records-wrapper">
                <Link to="/Appointments" className="record-box">
                    <img src={StaticImages['prior-visits-dashboard.svg']} alt="prior-visits" />
                    <div className="info">
                        <h4>Prior Visits</h4>
                        <p>Full visit archive and summaries</p>
                    </div>
                </Link>
                <Link to="/Labs" className="record-box">
                    <img src={StaticImages['lab-results-dashboard.svg']} alt="Lab-Results" />
                    <div className="info">
                        <h4>Lab Results</h4>
                        <p>View all current and past labs</p>
                    </div>
                </Link>
                <Link to="/Xrays" className="record-box">
                    <img src={StaticImages['x-rays-results-dashboard.svg']} alt="X-Rays-Results" />
                    <div className="info">
                        <h4>X-Ray Results</h4>
                        <p>Access x-ray images and results</p>
                    </div>
                </Link>
                <Link to="/Medications" className="record-box">
                    <img src={StaticImages['medications-dashboard.svg']} alt="Medications" />
                    <div className="info">
                        <h4>Medications</h4>
                        <p>Dosage, instructions, and more</p>
                    </div>
                </Link>
                <Link to="/HealthRecordSummary" className="record-box">
                    <img src={StaticImages['health-record-summary-dashboard.svg']} alt="Health-Record-Summary" />
                    <div className="info">
                        <h4>Health Record Summary</h4>
                        <p>Comprehensive health history</p>
                    </div>
                </Link>
            </div>
        </div>
    </main>
    )
};
export default Dashboard;