// src/profile.js
import React, { useState, useEffect } from 'react';
import BackButton from './components/back-button';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import PatientInformation from './components/PatientInformation';
import AccordionJSONSection from './components/AccordionJSONSection';
import Loading from './components/loading';
import { useNavigate } from 'react-router-dom';

function Profile(props) {
  const { user, isAuthenticated, event, context } = useAuth0();
  const [demographicData, setDemographicData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.personId != null && props.personId != "") {
      fetch(`/api/demographics/${props.personId}`, {headers: props.header})
        .then((res) => res.json())
        .then((data) => setDemographicData(data))
        .then(console.log(demographicData))
        .catch(error => {
          navigate("/500");
        })
    }
  }, [props.personId]);

  //for testing, let's default the person_id

  return (
    <>
      <div className="page-top">
        <BackButton />
        <h4><img src={user.picture} alt="Profile" style={{ 'max-height': "120px" }} className="rounded-circle img-fluid" /></h4>
      </div>

      {props.personId ? <div className="lab-results-wrapper profile-info">
        {demographicData? <PatientInformation
          data={demographicData}
          personId={props.personId}
          header={props.header}
        /> : <Loading/>}
      </div> : <p>No Medical Record</p>}

      {/*json data*/}
      <AccordionJSONSection name="Demographics" data={demographicData}/>
      <AccordionJSONSection name="Auth0" data={user}/>
    </>
  );
};
export default Profile;