// src/components/header.js
import React, { useEffect, useState } from 'react';
import LoginButton from './login-button';
import LogoutButton from './logout-button';
import SideBar from './sidebar';
import { Link } from "react-router-dom";
import { StaticImages } from "../Utility";

function Header(props) {
    const [menuVisible, setMenuInactive ] = useState(true);

    const toggleMenu = () => {
        setMenuInactive(!menuVisible);
        if(menuVisible)
            document.body.classList.remove('active-menu');
        else
            document.body.classList.add('active-menu');
    };

    return (
      <>
        <header className="header">
            <button className="menu-toggle-btn" onClick={toggleMenu}></button>
            
            <div className="logo-wrapper">
            
                <img src={StaticImages['nextcare-logo-white.png']} alt="nextcare logo" />
            </div>
            
            <div className="right-btns">
                
                <Link to="/Profile" className="profile-btn"><span>My Profile</span></Link>
                <LoginButton />
                <LogoutButton />
            </div>
        </header>
        <SideBar />
      </>
    );
}

export default Header;