import RowData from './RowData'
import StackedData from './StackedData';

function Condition(props){
    return <div className='content medictation'> 
        <h5>{props.name}</h5>
        <div className="info-table">
            {props.effective_date && <RowData label="Effective Dates" value={props.effective_date.toLocaleDateString('en-us', { month:"short", day:"numeric", year:"numeric"})} />}
            <RowData label="Last Related Visit" value={props.related_visit_date.toLocaleDateString('en-us', { month:"short", day:"numeric", year:"numeric"})} />
            {props.condition_status && <RowData label="Status" value={props.condition_status} />}
            <StackedData label="COMMENTS" value={props.comments} />
        </div>
    </div>
}
export default Condition;