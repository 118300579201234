export function dateFromNGDateString(NGDateString) {
  if (NGDateString == null) return;
    // console.log(NGDateString);
    var date = NGDateString.split("T")[0].split("-");
    var year = date[0];
    var month = parseInt(date[1])-1;
    var day = date[2];
    var time = NGDateString.split("T")[1].split(":");
    var hour = time[0];
    var minute = time[1];
    var second = time[2].split(".")[0];
    return new Date(year, month, day, hour, minute, second);
}

//Are we currently navigating to a page
export function NavAtResource(resource) {
  //console.log("checking resource for uri: " + resource);
  return (window.location.href.indexOf(resource) > -1 ? true : false)
}

export function importAllImages (r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}
export const StaticImages = importAllImages(require.context('./assets/images', false, /\.(png|jpe?g|svg)$/));

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString;
}

export function isGuid(stringData) {
  const guidRegex = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/
  return guidRegex.test(stringData);
}