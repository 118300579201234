import React, { useState } from 'react';
import RowData from './RowData'
import {formatPhoneNumber} from '../Utility';

function ContactInformation(props){
    const [isActive, setIsActive ] = useState(true);
    return <div className="accordion">
        <h4 className={isActive ? "accordion-title" : "accordion-title close"} onClick={() => setIsActive(!isActive)}>Contact Information</h4>
        {isActive && 
        <div className="content">
            <div className="address-box">
                <p>Address</p>
                <p>
                    {props.data.addressLine1 && <>{props.data.addressLine1}<br/></>}
                    {props.data.addressLine2 && <>{props.data.addressLine2}<br/></>}
                    {props.data.city + ", "}{props.data.state+" "}{props.data.zip}{", "+props.data.country}
                </p>
            </div>
            <div className="info-table">
                {props.data.cellPhone && <RowData label="Mobile Number" value={ formatPhoneNumber(props.data.cellPhone) } />}
                {props.data.homePhone && <RowData label="Home Number" value={ formatPhoneNumber(props.data.homePhone)} />}
                {props.data.dayPhone && <RowData label="Work Number" value={ formatPhoneNumber(props.data.dayPhone)} />}
            </div>
        </div>
        }
    </div>
}

export default ContactInformation;