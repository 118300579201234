import { useState, useEffect } from 'react';
import AccordionJSONSection from './components/AccordionJSONSection';
import BackButton from './components/back-button';
import Loading from './components/loading';
import DocumentAccordion from './components/DocumentAccordion';

function Documents(props) {
  const [docData, setDocData] = useState(null);
  useEffect(() => {
    if (props.personId != null && props.personId != "") {
      fetch(`/api/documents/${props.personId}`, {headers: props.header})
        .then((res) => res.json())
        .then((data) => setDocData(data))
        .then(console.log(docData))
    }
  }, [props.personId]);

  console.log("Fetched: ",docData);
  return (
    <>
      <div className="page-top">
        <BackButton/>
        <h4>My Documents</h4>        
      </div>
      {props.personId ? <div className="lab-results-wrapper">
        {docData ? <DocumentAccordion data={docData} personId={props.personId} header={props.header}/> : <Loading/>}
      </div> : <p>No Medical Record</p>}

      <AccordionJSONSection name="Documents" data={docData}/>
    </>
  );
}

export default Documents;