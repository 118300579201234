import RowData from './RowData'

function Allergy(props) {
    return <div className="content">
            <div className="info-table singe-child">
                <RowData label={props.name}/>
            </div>
        </div>
}

export default Allergy;    