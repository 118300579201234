//import logo from './logo.svg';
// import './main.css';
import './App.css';
import './'
//import { StyleSheet, Text, View } from 'react-native';
import React, { useLayoutEffect } from 'react';
import Appointments from './Appointments';
import AppointmentDetails from './AppointmentDetails';
import Results from './Results';
import Labs from './Labs';
import Documents from './Documents';
import Header from './components/header'
import Footer from './components/footer'
import Profile from './Profile'
import Dashboard from './Dashboard'
import XRay from './XRay'
import {Medications} from './Medications'
import HealthRecordSummary from './HealthRecordSummary'
import { TermsOfUse } from './TermsOfUse';
import {PrivacyPolicy} from './PrivacyPolicy'
import {Compliance} from './Compliance'
import {HIPAA} from './HIPAA'
import { E500 } from './Error';
import { MissingInfo } from './MissingInfo';
import {useAuth0} from '@auth0/auth0-react';
import  Loading  from './components/loading';
import { Link, Route, Routes, useLocation, useNavigate  } from "react-router-dom";
import { useState, useEffect } from 'react';
import {DependentSelector} from './components/DependentSelector'
import MissingInfoLink from './components/MissingInfoLink';


function App() {
  const { isLoading, isAuthenticated, loginWithRedirect, getIdTokenClaims } = useAuth0();
  const [personId, setPersonId] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [family, setFamily] = useState([]);
  const [defaultHeader, setDefaultHeader] = useState('');
  const [missingInfo, setMissingInfo] = useState({});
  
  useEffect(() => {
    //Auth0 token with nextgen person_id from app_metadata rule
    getIdTokenClaims()
      .then(claims  => {
        //console.log("isAuthenticated==> ", isAuthenticated);
        console.log("ID Token==> ", claims);
        console.log("Person ID: ", claims["https://localhost:3001/"].NEXTGEN_PERSON_ID);
        console.log(claims["https://localhost:3001/"]);
        setDefaultHeader({authorization: `Bearer ${claims.__raw}`});
        setPersonId(claims["https://localhost:3001/"].NEXTGEN_PERSON_ID); //#todo which token claim to read based on enviroment
        setAccountHolder(claims["https://localhost:3001/"].NEXTGEN_PERSON_ID);
        setFamily([claims["https://localhost:3001/"].NEXTGEN_PERSON_ID].concat(claims["https://localhost:3001/"].NEXTGEN_DEPENDENTS || []));
        setMissingInfo(claims["https://nextcare.com/missingInfo/"]);
      })
      .catch(err => {
        console.debug("token claim error: ", err)
      })
  }, [isAuthenticated]);  //only get a token when authentication status changes

  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const navigate = useNavigate();

  const swapPerson = (personId) => {
    setPersonId(personId);
  }

  if (isLoading) {
    return [ <Header/>, <Loading/>, <Footer/> ];
  }
  else if (!isAuthenticated) {
    navigate( loginWithRedirect() );
  }
  else {
    return (
      <>
      <Header/>
      <div className='main'>
        <div className='container'>
          <DependentSelector
            header={defaultHeader}
            swapPerson={swapPerson}
            personId={personId}
            family={family}
            accountHolder={accountHolder}
          ></DependentSelector>
          {accountHolder.toLowerCase() === personId.toLowerCase() &&
            <>
              <Routes>
                <Route path="/" element={<Dashboard/>} />
                <Route path="/Dashboard" element={<Dashboard/>} />
                <Route path="/Appointments" element={<Appointments personId={personId} header={defaultHeader}/>} />
                <Route path="/AppointmentDetails/:id" element={<AppointmentDetails personId={personId} header={defaultHeader}/>} />
                <Route path="/Results" element={<Results personId={personId} header={defaultHeader}/>} />
                <Route path="/Documents" element={<Documents personId={personId} header={defaultHeader}/>} />
                <Route path="/Labs" element={<Labs personId={personId} header={defaultHeader}/>} />
                <Route path="/Xrays" element={<XRay personId={personId} header={defaultHeader}/>} />
                <Route path="/Medications" element={<Medications personId={personId} header={defaultHeader}/>} />
                <Route path="/HealthRecordSummary" element={<HealthRecordSummary personId={personId} header={defaultHeader}/>} />
                <Route path="/Profile" element={<Profile personId={personId} header={defaultHeader}/>}/>
                <Route path="/TermsOfUse" element={<TermsOfUse/>} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
                <Route path="/Compliance" element={<Compliance/>} />
                <Route path="/HIPAA" element={<HIPAA/>} />
                <Route path="/500" element={<E500/>} />
                <Route path="/MissingInfo" element={<MissingInfo header={defaultHeader} missingInfo={missingInfo} setMissingInfo={setMissingInfo}/>} />
              </Routes>
            </>
          }
          <MissingInfoLink missingInfo={missingInfo}/>
        </div>
      </div>
      
      <Footer/>
      </>
       
    )
  }
}

export default App;