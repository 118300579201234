import React from 'react'
import { Link, useLocation, useNavigate  } from "react-router-dom";
import { useLayoutEffect } from 'react';
import { useState, useEffect } from 'react';

function MissingInfoLink(props) {
    const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const navigate = useNavigate();

  if (location.pathname != '/MissingInfo' && process.env.REACT_APP_Enable_Missing_Info_Request=='true' )
        return (
            <div className='page-bottom'> 
                {props.missingInfo == undefined &&
                  <Link to="/MissingInfo">
                    <p>Is your information missing?</p>
                  </Link>
                }
                {props.missingInfo == undefined ||
                  <p>Your missing information request is pending</p>
                }
                
            </div>
        )
  else
      return <></>
}

export default MissingInfoLink