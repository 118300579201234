// src/components/logout-button.js

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function LogoutButton() {
  const { isAuthenticated, logout } = useAuth0();

  return isAuthenticated && (
    <button className="primary-btn btn-small logout-btn" onClick={() => logout({ returnTo: window.location.origin, })}>Log Out</button>
  );
};

export default LogoutButton;