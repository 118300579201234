import React, { useState } from 'react';

function AccordionJSONSection(props){
    const [isActive, setIsActive ] = useState(false);
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "") {
        return ( 
            <div className="accordion" style={{'background-color': "#cdcdcd"}}>
                 <h4 className={isActive ? "accordion-title" : "accordion-title close"} onClick={() => setIsActive(!isActive)} >
                    {props.name} JSON Data
                </h4>
                <pre>
                {isActive && JSON.stringify( props.data , null, 2 ) }
                </pre>
            </div>
        );
    }
    else 
        return <></>;
}
  
export default AccordionJSONSection;