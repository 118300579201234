import RowData from './RowData'
import StackedData from './StackedData'
import {dateFromNGDateString} from '../Utility.js';

function Medication(props) {
    var startDate = props.endDate==null ? "" : dateFromNGDateString(props.startDate).toLocaleDateString('en-us', { month:"short", day:"numeric", year:"numeric"})
    var endDate = props.endDate==null ? "" : " - " + dateFromNGDateString(props.endDate).toLocaleDateString('en-us', { month:"short", day:"numeric", year:"numeric"})
    return <div className="content medictation">
        <h5 className="lab-text-box-title">{props.isGeneric? props.genericName : props.brandName}</h5>
        <p className="lab-text-box-diagnosis">Instructions</p>
        <p className="lab-text-box-desc">{props.instructions}</p>
        <div className="content">
            <div className="info-table">
                <RowData label="Dosage" value={props.dose}/>
                <RowData label="Effective Dates" value={startDate + endDate}/>
                <RowData label="Status" value={props.status}/>
                {props.comments && <StackedData label="Comments" value={props.comments}/>}
            </div>
        </div>
    </div>
}
export default Medication;