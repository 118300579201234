import React, { useState, useEffect } from 'react';

import Medication from './Medication'
import Loading from './loading'
import { act } from 'react-dom/test-utils';

function MedicationAccordion(props) {
    const [isActive, setIsActive] = useState(true);

    return <div className="accordion">
        <h4 className={isActive ? "accordion-title" : "accordion-title close"} onClick={() => setIsActive(!isActive)}>Medications</h4>
        <div className="content medication">
            {isActive && <ListMedications personId={props.personId} header={props.header}/>}
        </div>
    </div>
}

//required props: personId
export function ListMedications(props) {
    const [medData, setMedData] = useState(null);
    const [page, setPage] = useState(0);
    const [activePageTotal, setActivePageTotal] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasNext, setHasNext] = useState(false);
    const [hasNextActive, setHasNextActive] = useState(true);

    useEffect(() => {
        if (props.personId && props.personId != "" && props.header) {
            fetch(`/api/medications/${props.personId}`, {headers: props.header})
                .then((res) => res.json())
                .then((data) => {
                    setMedData(data);
                    setHasNextActive(data["hasNext"]);
                    setActivePageTotal(activePageTotal + 1);
                    setHasNext(true);
                })
                .then(console.log(medData))
        }
    }, [props.personId, props.header]);

    useEffect(() => {
        if (medData && page != 0) {
            setLoadingMore(true);
            if (hasNextActive) {
                fetch(`/api/medications/${props.personId}?page=${page}`, {headers: props.header})
                    .then(res => res.json())
                    .then(data => {
                        setMedData({items: medData["items"]
                            .concat(data["items"] //only add new items when it doesn't already exist in medData
                                .filter(item1 => medData["items"]
                                    .every(item2 => item2.medicationId != item1.medicationId)
                                )
                                //assure no duplicates in concatted array
                                .filter((item1, i, array) => array
                                    .findIndex(item2 => item2.medicationId === item1.medicationId) === i
                                )
                            )
                        });
                        setHasNext(data["hasNext"]);
                        setActivePageTotal(activePageTotal + 1);
                        setLoadingMore(false);
                    });
            } else {
                fetch(`/api/inactiveMedications/${props.personId}?page=${page - activePageTotal}`, {headers: props.header})
                    .then(res => res.json())
                    .then(data => {
                        setMedData({items: medData["items"]
                            .concat(data["items"] //only add new items when it doesn't already exist in medData
                                .filter(item1 => medData["items"]
                                    .every(item2 => item2.medicationId != item1.medicationId)
                                )
                                //assure no duplicates in concatted array
                                .filter((item1, i, array) => array
                                    .findIndex(item2 => item2.medicationId === item1.medicationId) === i
                                )
                            )
                        });
                        setHasNext(data["hasNext"]);
                        setLoadingMore(false);
                    });
            }
            
        }
        
    }, [page]);
    return <>
        {props.personId ? <>
            {medData ? medData["items"].map(med => <Medication key={med.id}
                genericName={med.genericName}
                brandName={med.brandName}
                isGeneric={med.isGenericSelected}
                instructions={med.sigDescription}
                dose={med.dose}
                doseForm={med.doseForm}
                startDate={med.startDate}
                endDate={med.endDate}
                status={med.status}
            />) : <Loading/>}
            {hasNext && <button onClick={() => setPage(page + 1)} disabled={loadingMore}>
                {loadingMore ? "Loading" : "Load More"}
            </button>}
        </> : <p>No Medical Record</p>}
    </>
}

export default MedicationAccordion;