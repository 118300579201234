// src/footer.js
import React from 'react';
import { Link } from "react-router-dom";
import Version from "./version";

function Footer() {
  return (
    <footer className="footer">
      <div className="copyright">
        <Version/>
      </div>
      <ul className="footer-nav">
        <li><Link to="/TermsOfUse">Terms of Use</Link></li>
        <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>
        <li><Link to="/Compliance">Compliance</Link></li>
        <li><Link to="/HIPAA">HIPAA</Link></li>
      </ul>
    </footer>
  );
}
export default Footer;